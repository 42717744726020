import { ReleaseStatusWithColorBar } from '@mid-react-common/common';
import { GridColDef } from '@mui/x-data-grid';
import text from '../../revit-update-content.text.json';

export const getColumns = (): GridColDef[] => [
  {
    field: 'releaseStatus',
    headerName: text.instancesToUpdateTableColumnReleaseStatus,
    width: 150,
    minWidth: 200,
    renderCell: (params) => <ReleaseStatusWithColorBar status={params.value} />,
  },
  {
    field: 'revitId',
    headerName: text.instancesToUpdateTableColumnRevitId,
    width: 150,
    minWidth: 150,
  },
  {
    field: 'product',
    headerName: text.instancesToUpdateTableColumnProductRelease,
    width: 350,
    minWidth: 200,
  },
  {
    field: 'familyName',
    headerName: text.instancesToUpdateTableColumnFamily,
    width: 350,
    minWidth: 150,
  },
];
