import { AuthService } from './auth';
import { identitySdkConfig } from './authConfig';
import { fusionBrowserApiService, getEnvironment } from 'mid-addin-lib';
import { CACHE_KEY_PREFIX } from '@adsk/identity-web-sdk/src/cache/shared';
import type { ForgeApiTokenResponse } from 'mid-types';
import { ApiPaths, logError, ServiceConfigMap, ServiceTypes } from 'mid-utils';

const POLL_INTERVAL = 100;

export class AuthServiceFusion extends AuthService {
  userScope: string | undefined;

  constructor() {
    super();
    this.userScope = identitySdkConfig.scope;
  }

  async start(): Promise<void> {
    await this._waitForFusionHostApiToBeAvailable();
    const response = await fusionBrowserApiService.getCachedOAuthTokens();

    // If the access token is not available, we initialize the authClient and ask the user to authenticate
    if (!response.value?.accessToken) {
      this.initialize();
      await this.authenticate();

      // After the user authenticates, we grab the tokens needed from the browser session storage and cache them
      await this._saveTokensFromBrowserSessionStorage();
    }
  }

  override async getOAuth2Token(): Promise<string> {
    await this._waitForFusionHostApiToBeAvailable();
    const { value } = await fusionBrowserApiService.getCachedOAuthTokens();

    // If the token is expired, we use the refresh token to get a new one
    if (value && value.accessToken && value.refreshToken && this._isTokenExpired(value.accessToken)) {
      try {
        return await this._getAccessTokenUsingRefreshToken(value.refreshToken);
      } catch (e) {
        logError(e);
        // If the refresh token is invalid, we ask the user to authenticate again
        await fusionBrowserApiService.setCachedOAuthTokens('', '');
        await this.start();
      }
    }

    // If the token is not expired, we return it
    if (value && value.accessToken) {
      return value.accessToken;
    }

    // If the token has not been cached, we return it from the authClient
    return await this.authClient.getAccessTokenSilently();
  }

  async _saveTokensFromBrowserSessionStorage(): Promise<void> {
    // We look for the refresh token in the cache
    const key = Object.keys(sessionStorage).find((key) => key.includes(CACHE_KEY_PREFIX));
    const value = key ? sessionStorage.getItem(key) : undefined;

    if (value) {
      // Then we save the refresh token in the cache
      const { body } = JSON.parse(value);
      await fusionBrowserApiService.setCachedOAuthTokens(body.access_token, body.refresh_token);
    }
  }

  _isTokenExpired(token: string): boolean {
    const splitToken = token.split('.')[1];
    const decodedToken = JSON.parse(atob(splitToken));
    const expiration = decodedToken.exp;
    const toMilliseconds = 1000;

    // Date.now() uses milliseconds. We multiply by 1000 to convert the expiration time to milliseconds
    return expiration * toMilliseconds < Date.now();
  }

  async _getAccessTokenUsingRefreshToken(refreshToken: string): Promise<string> {
    try {
      const env = await getEnvironment();
      const url = ServiceConfigMap[ServiceTypes.FORGE_API][env];
      const path = `/${ApiPaths.AUTHENTICATION_API_PATH}/token`;

      const payload = new URLSearchParams();
      payload.append('grant_type', 'refresh_token');
      payload.append('refresh_token', refreshToken);
      payload.append('client_id', process.env.VITE_AUTH_CONFIG_CLIENT_ID || '');
      if (this.userScope) {
        payload.append('scope', this.userScope);
      }

      const response = await fetch(url.api + path, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: payload,
      });

      const data: ForgeApiTokenResponse = await response.json();
      await fusionBrowserApiService.setCachedOAuthTokens(data.access_token, data.refresh_token);

      return data.access_token;
    } catch (e: unknown) {
      logError(e);
      throw e;
    }
  }

  // This method is used to wait for the Fusion host API to be available
  // It takes some arbitrary amount of time for `window.adsk` and `window.neutronJavaScriptObject`
  // to be available after the page is loaded
  async _waitForFusionHostApiToBeAvailable(): Promise<void> {
    await new Promise((resolve) => {
      if (window.adsk && window.neutronJavaScriptObject) {
        resolve(true);
      } else {
        const interval = setInterval(() => {
          if (window.adsk && window.neutronJavaScriptObject) {
            clearInterval(interval);
            resolve(true);
          }
        }, POLL_INTERVAL);
      }
    });
  }
}

export default new AuthServiceFusion();
