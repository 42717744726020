import { ProductRelease, ReleaseStatus } from '@adsk/offsite-dc-sdk';
import { NotificationContext } from '@mid-react-common/common';
import { GridRowModel } from '@mui/x-data-grid';
import { GridRowSelectionModel } from '@mui/x-data-grid';
import { getAllMIDInstancesData, selectAndEditMidInstance } from 'mid-addin-lib';
import { getDcApiServiceInstance } from 'mid-api-services';
import { isFulfilled, isRejected } from 'mid-utils';
import { useCallback, useContext, useEffect, useState } from 'react';
import text from '../../revit-update-content.text.json';
import { Instance, InstancePromiseData, ProductReleasesMap } from './types';
import { AccBridgeSourceProjectDataQueryParams } from 'mid-types';

interface useInstancesToUpdateState {
  tableData: GridRowModel[];
  isReplaceAvailable: boolean | undefined;
  allInstances: Instance[];
  errorState: boolean;
  isFetching: boolean;
  selectedInstance: Instance | undefined;
  handleReplaceClick: () => void;
  handleInstanceSelection: (selectionModel: GridRowSelectionModel) => void;
  handleRefreshOutdatedInstances: () => void;
}

export const useInstancesToUpdate = (): useInstancesToUpdateState => {
  const { logAndShowNotification } = useContext(NotificationContext);
  const dcApiService = getDcApiServiceInstance();
  const [allInstances, setAllInstances] = useState<Instance[]>([]);
  const [productReleaseMap, setProductReleaseMap] = useState<ProductReleasesMap>({});
  const [isReplaceAvailable, setIsReplaceAvailable] = useState<boolean | undefined>();
  const [errorState, setErrorState] = useState<boolean>(false);
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [selectedInstance, setSelectedInstance] = useState<Instance | undefined>();

  const fetchInstancesToUpdateTableData = useCallback(async () => {
    try {
      setIsFetching(true);
      // Get all Informed Design instance data from the Revit model
      const instances = await getAllMIDInstancesData();

      // Map of all the data missing from the instances, per contentId
      const productReleaseMap = instances.reduce<ProductReleasesMap>((acc, instance) => {
        if (!acc[instance.contentId]) {
          acc[instance.contentId] = {
            tenancyId: instance.tenancyId,
            targetTenancyId: instance.targetTenancyId,
            folderUrn: instance.folderUrn,
            name: '',
            numberOfReleases: -1,
            releases: {},
            latestNonObsoleteRelease: -1,
          };
        }

        if (!acc[instance.contentId].releases.hasOwnProperty(instance.releaseNumber)) {
          acc[instance.contentId].releases[instance.releaseNumber] = undefined;
        }

        return acc;
      }, {});

      // Fetching missing information needed to populate the table
      const productReleaseAdditionalData: InstancePromiseData[] = [];
      const contentIds = Object.keys(productReleaseMap);

      for (const contentId of contentIds) {
        const { tenancyId, targetTenancyId, folderUrn } = productReleaseMap[contentId];
        const isAccBridgeDataAvailable = !!targetTenancyId && !!folderUrn;
        const incomingAccBridgeData: AccBridgeSourceProjectDataQueryParams | undefined = isAccBridgeDataAvailable
          ? {
              sourceFolderUrn: folderUrn,
              sourceProjectId: tenancyId,
              targetProjectId: targetTenancyId,
            }
          : undefined;
        const productReleasesPromises = [];

        const allReleasesByProduct = dcApiService.getProductReleasesList({
          projectId: tenancyId,
          productId: contentId,
          incomingAccBridgeData,
        });

        productReleasesPromises.push(allReleasesByProduct);

        const promiseResults = await Promise.allSettled(productReleasesPromises);
        const successfulProductReleases = promiseResults.filter(isFulfilled).flatMap((result) => result.value);

        // All default or active releases below this release are the ones we want to display in the table
        const latestNonObsoleteRelease = successfulProductReleases.reduce<ProductRelease | null>(
          (latest, current) =>
            current.status !== ReleaseStatus.OBSOLETE && (!latest || current.release > latest.release) ? current : latest,
          null,
        );

        const releaseNumbers = Object.keys(productReleaseMap[contentId].releases);
        for (const releaseNumber of releaseNumbers) {
          const releaseData = successfulProductReleases.find(
            (release) =>
              release.tenancyId === productReleaseMap[contentId].tenancyId &&
              release.release === parseInt(releaseNumber) &&
              release.contentId === contentId,
          );

          if (releaseData) {
            productReleaseAdditionalData.push({
              contentId,
              releaseNumber: parseInt(releaseNumber),
              numberOfReleases: successfulProductReleases.length,
              latestNonObsoleteRelease: latestNonObsoleteRelease?.release || -1,
              status: releaseData.status,
              name: releaseData.name,
            });
          }
        }
        const failedProductReleases = promiseResults.filter(isRejected);
        if (failedProductReleases.length) {
          logAndShowNotification({ message: text.notificationFetchProductReleasesFailed });
        }
      }

      // Populating the map with the missing data
      productReleaseAdditionalData.map((release) => {
        const { contentId, releaseNumber, status, name, numberOfReleases, latestNonObsoleteRelease } = release;
        productReleaseMap[contentId].releases[releaseNumber] = status;
        productReleaseMap[contentId].name = name;
        productReleaseMap[contentId].numberOfReleases = numberOfReleases;
        productReleaseMap[contentId].latestNonObsoleteRelease = latestNonObsoleteRelease;
      });

      // Filtering out the instances that are already updated to the latest release
      const filteredInstances = instances.filter(
        (instance) =>
          instance.releaseNumber < productReleaseMap[instance.contentId].latestNonObsoleteRelease ||
          productReleaseMap[instance.contentId].releases[instance.releaseNumber] === ReleaseStatus.OBSOLETE,
      );

      // Table data
      const updatedInstances: Instance[] = filteredInstances.map((instance) => {
        const releaseStatus = productReleaseMap[instance.contentId].releases[instance.releaseNumber];
        const name = productReleaseMap[instance.contentId].name;
        return { ...instance, releaseStatus, productName: name };
      });

      setProductReleaseMap(productReleaseMap);
      setAllInstances(updatedInstances);
      setIsFetching(false);
    } catch (error) {
      logAndShowNotification({ error });
      setIsFetching(false);
      setErrorState(true);
    }
  }, [dcApiService, logAndShowNotification]);

  useEffect(() => {
    fetchInstancesToUpdateTableData();
  }, [dcApiService, fetchInstancesToUpdateTableData, logAndShowNotification]);

  const tableData: GridRowModel[] = allInstances
    ? allInstances.map((instance) => ({
        releaseStatus: instance.releaseStatus,
        revitId: instance.elementId,
        familyName: instance.familyName,
        product: `${instance.productName} (${instance.releaseNumber} of ${
          productReleaseMap[instance.contentId].numberOfReleases
        })`,
      }))
    : [];

  const handleInstanceSelection = (selectionModel: GridRowSelectionModel) => {
    const selectedInstance = selectionModel.map((rowId) => allInstances.find((instance) => instance.elementId === rowId))[0];
    if (selectedInstance) {
      setSelectedInstance(selectedInstance);
      // Check if selected instance is below the latest non-obsolete release
      const isReplaceAvailable =
        selectedInstance.releaseNumber < productReleaseMap[selectedInstance.contentId].latestNonObsoleteRelease;
      setIsReplaceAvailable(isReplaceAvailable);
    }
  };

  const handleReplaceClick = () => {
    if (selectedInstance) {
      selectAndEditMidInstance(selectedInstance.elementId);
    }
  };

  const handleRefreshOutdatedInstances = () => {
    fetchInstancesToUpdateTableData();
  };

  return {
    tableData,
    isFetching,
    isReplaceAvailable,
    allInstances,
    errorState,
    selectedInstance,
    handleReplaceClick,
    handleInstanceSelection,
    handleRefreshOutdatedInstances,
  };
};
